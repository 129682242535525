<template></template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { ModalBus } from '@/eventBus';

const RecipeModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/recipes/RecipeModal' /* webpackChunkName: "recipeModal" */),
}));

export default {
  mounted() {
    const recipeId = parseInt(this.$route.params.recipeId, 10);

    ModalBus.emit('open', {
      component: RecipeModal,
      closeCallback: this.onClose,
      modifiers: 'recipe',
      props: { recipeId },
    });
  },

  methods: {
    onClose() {
      this.$router.push({ name: 'recipes' });
    },
  },
};
</script>
